import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import { Link } from "gatsby";
import theme from "theme";

import Banner from "components/Banner";
import styled from "styled-components";

const SectionContainer = styled.div`
  margin: 8% 0;
  height: 100%;
  position: relative;
  ${theme.screens.smScreen} {
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 575px) {
      width: auto;
      bottom: -25px;
    }

    @media screen and (max-width: 440px) {
      width: 65%;
      bottom: -25px;
    }
  }
`;
const ThankYou = ({ pageContext }) => {
  const ty = pageContext.pageContent;

  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SectionContainer>
        <Banner title={ty.title} description={ty.subtitle} padding="greater" />
        <Link to="/" className="btn">
          Main Page
        </Link>
      </SectionContainer>
    </Layout>
  );
};

export default ThankYou;
